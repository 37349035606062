// ==============================
// Custom style
// ==============================

body:not([theme=dark]) {
	background-color: #f3f3f7;
}

body:not([theme=dark]) .header-wrapper {
	background-color: #1b223e;
}

body:not([theme=dark]) .header-title a.header-logo,
body:not([theme=dark]) .menu-item {
	color: #f5f5f5;
}

body:not([theme=dark]) #header-desktop div.header-wrapper div.menu span.menu-item.delimiter {
	border-left: 1.5px solid #f5f5f5;
}

body:not([theme=dark]) #header-desktop div.header-wrapper div.menu a.active {
	color: #f5f5f5;
}

body:not([theme=dark]) span.svg-icon {
    background-image: url(/img/icon/sprite-dark.svg);
}

body:not([theme=dark]) .content {
	color: #616161;
}

body:not([theme=dark]) .post, body:not([theme=dark]) .post-title {
	background-color: #ffffff;
}

body:not([theme=dark]) a.tag {
	border-color: #777;
	color: #777;
}

body:not([theme=dark]) #header-mobile .header-container div.header-wrapper .menu-toggle span {
	background: #f5f5f5;
}

body:not([theme=dark]) #header-mobile .header-container .menu.active {
	background: #1b223e;
}
