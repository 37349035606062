@media only screen and (min-width: 1300px) {
  .page-toc {
    .article-post {
      margin-right: auto;
      margin-left: auto;
    }

    .content-block-position {
      margin: 3rem auto 0;
    }
  }

  .theme-mega-full, .theme-hero {
    .content-block-first {
      margin: 2rem auto;
    }
  }

  .theme-classic.page-toc, .theme-wide.page-toc, .theme-full.page-toc {
    .header-post {
      margin-right: auto;
      margin-left: auto;
    }
  }

  .theme-full {
    .header-post {
      .featured-image {
        height: 40vh;
      }
    }
  }

  .page, .content-block-position {
    width: 100%;
  }

  .post-share-toc {
    margin: 1rem auto;
  }
}

@media only screen and (min-width: 1201px) {
  .page, .content-block-position {
    width: 100%;
  }

  .article-post {
    font-size: 19px;

    .post {
      padding: 45px 60px;
    }

  }

  .featured-image-preview, .featured-image {
    img {
      border-top-left-radius: $article-summary-image-border-radius;
      border-top-right-radius: $article-summary-image-border-radius;
    }
  }

  .padding-article {
    padding: 40px 60px 20px 60px;
  }

}

@media only screen and (max-width: 1200px) {
  #toc-auto {
    display: none;
  }

  #toc-static {
    display: block;
  }

  .page, .content-block-position {
    width: 100%;
  }

  .theme-mega-full.page-toc, .theme-hero.page-toc {
    .content-block-position {
      margin: 2rem auto;
    }

    .content-break {
      margin-right: auto;
      margin-bottom: 3rem;
      margin-left: auto;
    }
  }

  .page-toc {
    .article-post {
      margin-right: auto;
      margin-left: auto;
    }

    .content-block-position {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .theme-classic.page-toc, .theme-wide.page-toc, .theme-full.page-toc {
    .header-post {
      margin-top: 6rem;
      margin-right: auto;
      margin-bottom: 0;
      margin-left: auto;
    }
  }

  .article-post {
    font-size: 19px;

    .post {
      padding: 45px 60px;
    }
  }

  .featured-image-preview, .featured-image {
    img {
      border-radius: 30px 30px 0 0;
    }
  }

  .padding-article {
    padding: 40px 60px 20px 60px;
  }

}

@media only screen and (max-width: 960px) {
  .page, .content-block-position {
    width: 100%;
  }

  .article-post {
    font-size: 19px;

    .post {
      padding: 45px 60px;
    }
  }

  .featured-image-preview, .featured-image {
    img {
      border-top-left-radius: $article-summary-image-border-radius;
      border-top-right-radius: $article-summary-image-border-radius;
    }
  }

  .padding-article {
    padding: 40px 60px 20px 60px;
  }

  .img-right {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  .img-size-30, .img-size-50, .img-size-40 {
    width: 100%;
  }

}

@media only screen and (max-width: 680px) {

  .home {
    .home-profile {
      .home-avatar {
        display: none;
      }
    }
  }

  .article-post {
    font-size: 16px;

    .post {
      padding: 10px 10px 30px 10px;
    }

    .content-block {
      border-radius: 0;
    }

    .featured-image {
      border-radius: 0;
    }

    .content-block-position:first-child {
      margin-top: 0;
    }

    .content-break h2 {
      margin-top: 0;
    }
  }

  .header-post {
    border-radius: 0;
    margin-top: 0;
  }

  .comments {
    padding: 10px 10px 10px;
  }

  .featured-image {
    height: 100vh;
  }

  .theme-wide {
    .image-theme-wide {
      margin-right: -10px;
      margin-left: -10px;
    }
  }

  .theme-classic, .theme-wide, .theme-full {
    .header-post {
      margin-top: 3rem;
    }
  }

  .theme-classic, .theme-wide, .theme-full, .theme-mega-full, .theme-hero {
    .article-post {
      margin-top: 0;
    }
  }

  .single {
    border-radius: 0;
  }

  .featured-image-preview, .featured-image {
    img {
      border-top-left-radius: $article-summary-image-border-radius;
      border-top-right-radius: $article-summary-image-border-radius;
    }
  }

  .padding-article {
    padding: 40px 10px 0 10px;
  }

  figure.render-image {
    display: block;
    margin-left: -10px;
    margin-right: -10px;
  }

  .split-post {
    margin-right: -10px;
    margin-left: -10px;
  }

  .post-all-meta {
    padding: 20px 10px 20px
  }

  .author-comment-text {
    font-size: 18px;
  }

  .post-navigation {
    display: inline-block;
    width: 100%;

    .nav-box-prev {
      margin-right: 0;
    }

    .post-nav-box {
      width: 100%;
      padding-top: 10px;
    }
  }

  .post-tags {
    .tag {
      padding: 2px 5px 2px 5px;
      font-size: 14px;
    }
  }

  .post-tags-summary-image {
    display: none;
  }

  .post-tags-summary-under-footer-display {
    display: inline-block;
  }

  .block-media_column {
    .content-column {
      width: 100%;
      padding-bottom: 15px;
    }
  }

  .block-media_right, .block-media_left {
    width: 100%;
    text-align: center;
    float: none;
    margin-left: 0;
  }

  .cat-cit {
    padding: 0;
    border: 0;

    [theme=dark] & {
      background: initial;
    }
  }

  .cat-cit:before {
    content: "";
    width: 100%;
    border-bottom: 3px dashed #2c678d;
    margin-bottom: 20px;
    display: block;
  }

  .cat-cit:after {
    content: "";
    width: 100%;
    border-top: 3px dashed #2c678d;
    margin-top: 20px;
    display: block;
  }

  .breadcrumbs, span.tag-none-display {
    display: none;
  }

}

@media only screen and (max-width: 400px) {
  .home {
    .article-post {
      .featured-image-preview {
        display: none;
      }
    }
  }

  .rendered {
    margin-top: 15px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .post-meta {
    .post-meta-views, .post-meta-comments {
      display: none;
    }
  }

  .block-author {
    .author-avatar {
      display: none;
    }
  }
}

@media print {

  div.menu, .fixed-button, .toc, #toc-auto, .post-info-share, .post-footer, .comments, .post-tags, footer, .breadcrumbs {
    display: none !important;
  }

  .content-block-first, .content-block {
    box-shadow: none;
  }

  .header-post {
    box-shadow: none;
    position: static;
    height: auto;

    .featured-image {
      height: 0;
    }

    .post-title {
      position: static;
      display: block;
      top: 0;
    }

  }

  #header-desktop {
    box-shadow: none;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
    position: absolute;

    .header-wrapper {
      padding: 0;
    }
  }

  .post-all-meta {
    padding: 0;
  }

  .single {
    .admonition {
      .admonition-title {
        border-bottom-width: 5px;
      }
    }
  }


  .page, .content-block-position {
    [data-header-desktop] & {
      margin-top: 0;
    }
  }

  .single {
    .post-meta {
      display: none;
    }

    .post {
      .admonition {
        .details-icon {
          display: none;
        }
      }
    }
  }

  .article-post {
    .content-block {
      box-shadow: none;
    }

    .content-block-position:first-child {
      margin-top: 0;
    }

    .featured-image {
      height: 0;
    }
  }

  .theme-full, .theme-classic, .theme-wide {
    .content-block-first {
      box-shadow: none;
    }
  }
}

@media screen and (min-width: 900px) {
  .post-hero {
    display: block;
  }

  .post-hero.mobile {
    display: none;
  }

  .post-hero-grid {
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 16px 0;
  }

  .hero-text-container {
    padding: 0;
  }

  .hero-text-container span {
    font-size: 13px;
    letter-spacing: 1.35px;
    line-height: 24px;
  }

  .hero-text-container h1 {
    opacity: 0.9;
    font-weight: 500;
    font-size: 40px;
    letter-spacing: 0px;
    line-height: 48px;
    margin-bottom: 24px;
  }

  .hero-text-container p {
    font-size: 24px;
    color: #606060;
    letter-spacing: 0.2px;
    line-height: 32px;
    margin-bottom: 16px;
  }

  .hero-author {
    padding: 0;
  }

  .hero-author h4 {
    font-size: 16px;
    color: #242424;
    letter-spacing: -0.2px;
    line-height: 24px;
  }

  .hero-author p {
    font-weight: 300;
    font-size: 16px;
    color: #606060;
    letter-spacing: 0.1px;
  }

  .rendered {
    font-weight: 300;
    font-size: 16px;
    color: #606060;
    letter-spacing: 0.1px;
  }

  .hero-img {
    height: 528px;
    width: 528px;
  }
}

@media screen and (max-width: 900px) {
  .hero-text-container {
    padding-top: 6rem;
  }

  .post-hero-grid .post-hero-logo {
    display: none;
  }

  .post-hero-grid .post-hero-info {
    margin-right: 0;
  }
}

@media screen and (min-width: 400px) {
  .hero-img {
    height: 450px;
    width: 450px;
  }
}

@media screen and (min-width: 1200px) {
  .hero-img {
    width: 588px;
    height: 528px;
  }

  .hero-text-container h1 {
    font-weight: 600;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -1.35px;
  }
}
