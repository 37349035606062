.post-info-share {
  text-align: center;
  padding: 40px 0 40px 0;
}

.post-share {
  a.share-icon {
    border-bottom: 2px solid;
  }

  a.share-icon:hover {
    background: white;
    @include transition(background 0.2s ease);

    .svg-social-icon {
      background-image: url(/img/icon/sprite-social-color.svg);
    }

    [theme=dark] & {
      background: $global-background-secondary-color-dark;
    }
  }
}

.share-link {
  margin-top: 15px;
  margin-right: 8px;
  display: inline-block;

  a.share-telegram {
    background: #2CA5E0;
    border-color: #2CA5E0;
  }

  a.share-twitter {
    background: #1DA1F2;
    border-color: #1DA1F2;
  }

  a.share-vk {
    background: #4680C2;
    border-color: #4680C2;
  }

  a.share-facebook {
    background: #1877F2;
    border-color: #1877F2;
  }

  a.share-whatsapp {
    background: #25D366;
    border-color: #25D366;
  }

  a.share-pocket {
    background: #EF3F56;
    border-color: #EF3F56;
  }

  a.share-linkedin {
    background: #0077B5;
    border-color: #0077B5;
  }

  a.share-viber {
    background: #665CAC;
    border-color: #665CAC;
  }

  a.share-pinterest {
    background: #BD081C;
    border-color: #BD081C;
  }

  a.share-tumblr {
    background: #36465D;
    border-color: #36465D;
  }

  a.share-reddit {
    background: #FF4500;
    border-color: #FF4500;
  }

  a.share-buffer {
    background: #168EEA;
    border-color: #168EEA;
  }

  a.share-xing {
    background: #006567;
    border-color: #006567;
  }

  a.share-line {
    background: #00C300;
    border-color: #00C300;
  }

  a.share-instapaper {
    background: #1F1F1F;
    border-color: #1F1F1F;
  }

  a.share-digg {
    background: #000000;
    border-color: #000000;
  }

  a.share-stumbleupon {
    background: #FD8235;
    border-color: #FD8235;
  }

  a.share-flipboard {
    background: #E12828;
    border-color: #E12828;
  }

  a.share-weibo {
    background: #20B8E5;
    border-color: #20B8E5;
  }

  a.share-renren {
    background: #217DC6;
    border-color: #217DC6;
  }

  a.share-myspace {
    background: #030303;
    border-color: #030303;
  }

  a.share-blogger {
    background: #FF5722;
    border-color: #FF5722;
  }

  a.share-baidu {
    background: #2319DC;
    border-color: #2319DC;
  }

  a.share-ok {
    background: #EE8208;
    border-color: #EE8208;
  }

  a.share-evernote {
    background: #00A82D;
    border-color: #00A82D;
  }

  a.share-skype {
    background: #00AFF0;
    border-color: #00AFF0;
  }

  a.share-trello {
    background: #0079BF;
    border-color: #0079BF;
  }

  a.share-mix {
    background: #FF8126;
    border-color: #FF8126;
  }

  a.share-hackernews {
    background: #FF8126;
    border-color: #FF8126;
  }

}

.share-text {
  font-size: 30px;
  margin-bottom: 10px;
}

.share-icon {
  border-radius: 6px;
  padding: 10px 12px 4px 12px;
  border: 2px solid;
}

.svg-social-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(/img/icon/sprite-social.svg);
  width: 20px;
  height: 20px;
}
.svg-social-icon-color {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(/img/icon/sprite-social-color.svg);
  width: 20px;
  height: 20px;
}

.icon-rss {
  background-position: 0 0;
}
.icon-mail-dot-ru {
  background-position: -25px 0;
}
.icon-udemy {
  background-position: -50px 0;
}
.icon-discord {
  background-position: -75px 0;
}
.icon-docker {
  background-position: -100px 0;
}
.icon-matrix {
  background-position: -125px 0;
}
.icon-xmpp {
  background-position: -150px 0;
}
.icon-gitea {
  background-position: -175px 0;
}
.icon-mastodon {
  background-position: -200px 0;
}
.icon-researchgate {
  background-position: -225px 0;
}
.icon-google {
  background-position: 0 -25px;
}
.icon-vine {
  background-position: -25px -25px;
}
.icon-wordpress {
  background-position: -50px -25px;
}
.icon-dribbble {
  background-position: -75px -25px;
}
.icon-behance {
  background-position: -100px -25px;
}
.icon-deviantart {
  background-position: -125px -25px;
}
.icon-jsfiddle {
  background-position: -150px -25px;
}
.icon-angellist {
  background-position: -175px -25px;
}
.icon-zhihu {
  background-position: -200px -25px;
}
.icon-strava {
  background-position: -225px -25px;
}
.icon-twitch {
  background-position: 0 -50px;
}
.icon-steam {
  background-position: -25px -50px;
}
.icon-patreon {
  background-position: -50px -50px;
}
.icon-kickstarter {
  background-position: -75px -50px;
}
.icon-foursquare {
  background-position: -100px -50px;
}
.icon-last-dot-fm {
  background-position: -125px -50px;
}
.icon-goodreads {
  background-position: -150px -50px;
}
.icon-500px {
  background-position: -175px -50px;
}
.icon-paypal {
  background-position: -200px -50px;
}
.icon-bandcamp {
  background-position: -225px -50px;
}
.icon-spotify {
  background-position: 0 -75px;
}
.icon-soundcloud {
  background-position: -25px -75px;
}
.icon-snapchat {
  background-position: -50px -75px;
}
.icon-xing {
  background-position: -75px -75px;
}
.icon-flickr {
  background-position: -100px -75px;
}
.icon-stackoverflow {
  background-position: -125px -75px;
}
.icon-bitbucket {
  background-position: -150px -75px;
}
.icon-freecodecamp {
  background-position: -175px -75px;
}
.icon-codepen {
  background-position: -200px -75px;
}
.icon-pinterest {
  background-position: -225px -75px;
}
.icon-keybase {
  background-position: 0 -100px;
}
.icon-quora {
  background-position: -25px -100px;
}
.icon-tumblr {
  background-position: -50px -100px;
}
.icon-youtube {
  background-position: -75px -100px;
}
.icon-gitlab {
  background-position: -100px -100px;
}
.icon-medium {
  background-position: -125px -100px;
}
.icon-instagram {
  background-position: -150px -100px;
}
.icon-linkedin {
  background-position: -175px -100px;
}
.icon-github {
  background-position: -200px -100px;
}
.icon-mixer {
  background-position: -225px -100px;
}
.icon-trello {
  background-position: 0 -125px;
}
.icon-skype {
  background-position: -25px -125px;
}
.icon-evernote {
  background-position: -50px -125px;
}
.icon-odnoklassniki {
  background-position: -75px -125px;
}
.icon-baidu {
  background-position: -100px -125px;
}
.icon-blogger {
  background-position: -125px -125px;
}
.icon-myspace {
  background-position: -150px -125px;
}
.icon-renren {
  background-position: -175px -125px;
}
.icon-sinaweibo {
  background-position: -200px -125px;
}
.icon-flipboard {
  background-position: -225px -125px;
}
.icon-digg {
  background-position: 0 -150px;
}
.icon-pocket {
  background-position: -25px -150px;
}
.icon-instapaper {
  background-position: -50px -150px;
}
.icon-line {
  background-position: -75px -150px;
}
.icon-xing1 {
  background-position: -100px -150px;
}
.icon-buffer {
  background-position: -125px -150px;
}
.icon-reddit {
  background-position: -150px -150px;
}
.icon-tumblr1 {
  background-position: -175px -150px;
}
.icon-pinterest1 {
  background-position: -200px -150px;
}
.icon-viber {
  background-position: -225px -150px;
}
.icon-linkedin1 {
  background-position: 0 -175px;
}
.icon-telegram {
  background-position: -25px -175px;
}
.icon-whatsapp {
  background-position: -50px -175px;
}
.icon-facebook {
  background-position: -75px -175px;
}
.icon-vk {
  background-position: -100px -175px;
}
.icon-twitter {
  background-position: -125px -175px;
}
.icon-leanpub {
  width: 23px;
  height: 20px;
  background-position: 0 -200px;
}
.icon-hacker-news {
  width: 17px;
  height: 20px;
  background-position: -50px -200px;
}
.icon-stumbleupon {
  width: 21px;
  height: 20px;
  background-position: -75px -200px;
}